<template>
  <section class="home-analytics-section">
    <div class="home-analytics-section__container">
      <div class="home-analytics-section__heading">
        <h1 class="home-analytics-section__title">
          {{ t('title') }}
        </h1>
        <p class="home-analytics-section__tip">
          {{ t('tip') }}
        </p>
      </div>
      <h3 class="home-analytics-section__subtitle">
        {{ t('subtitle') }}
      </h3>
      <div class="home-analytics-section__list">
        <div
          v-for="(item, index) in tm('items')"
          :key="index"
          class="home-analytics-section__item"
        >
          <p class="home-analytics-section__item-number">
            [0{{ index + 1 }}]
          </p>
          <div class="home-analytics-section__item-card is-big-text">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import messages from '../model/homeAnalyticsSectionMessages.json';

const { t, tm } = useI18n({ messages });
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/devices'

.home-analytics-section
  padding 72rem

  background-color #000

  &__heading
    display flex
    justify-content space-between
    align-items center

  &__title
    color #2DE115

    text-transform uppercase

  &__tip
    max-width 710rem

    font-size 25rem
    font-weight 300
    line-height 27rem

  &__subtitle
    margin-top 54rem

    color #2DE115

    font-weight 300

  &__list
    margin-top 38rem

    display grid
    grid-template-columns repeat(4, 1fr)
    gap 22rem

  &__item
    display grid
    gap 8rem

    transition transform $transition-basic-short

    &:hover
      transform translateY(-10rem)

    &:hover &-card
      background-color $gray-dark

  &__item-number
    margin-left 16rem

    font-weight 300

    color #B1B1B1

  &__item-card
    position relative

    padding 14rem 16rem
    height 170rem

    display flex
    align-items flex-end

    background-color #121212
    border 1px solid #B1B1B1
    border-radius 20rem

    transition background-color $transition-basic-short

    &::before
      content ''
      position absolute
      top 16rem
      right 16rem

      width 10rem
      height 10rem

      background #2DE115

  @media all and (max-width $mobile_media)
    padding 80rem 16rem

    &__heading
      display grid
      gap 20rem

      text-align center

    &__tip
      font-size 12rem
      line-height 14rem

    &__subtitle
      margin-top 24rem

      font-size 14rem
      line-height 16rem

      text-align center

    &__list
      margin-top 16rem

      grid-template-columns repeat(2, 1fr)

    &__item-number
      margin-left 0

      font-size 8rem
      line-height 10rem

    &__item-card
      height 100rem

      border-radius 8rem

      font-size 10rem
      line-height 12rem

      &::before
        top 12rem
        right 12rem

        width 5rem
        height 5rem
</style>
