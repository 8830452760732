<template>
  <Suspense>
    <slot />
  </Suspense>
</template>

<script setup>
import { useLanguageStore } from '@/shared/config/lang';

defineOptions({
  inheritAttrs: false,
});

const languageStore = useLanguageStore();
await languageStore.fetchAllLanguages();
</script>
