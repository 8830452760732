<template>
  <label
    class="input"
    :class="{
      'input--focused': isFocused,
      'input--filled': isFilled,
      'input--with-icon': isWithIcon,
      'input--error': isError,
      'input--success': isSuccess,
      'input--disabled': isDisabled,
    }"
    :for="id"
  >
    <p v-if="slots.label" class="input__label is-small-text">
      <slot name="label"/>
    </p>
    <span class="input__wrapper">
      <input
        v-bind="attrs"
        :id="id"
        class="input__tag"
        :value="modelValue"
        :placeholder="slots.default ? getText(slots.default()[0].children) : ''"
        @input="input"
        @change="change"
        @invalid="invalid"
        @focus="focus = true"
        @blur="focus = false"
      />
      <span v-if="icon || withIcon || slots.icon" class="input__icon">
        <slot name="icon">
          <basic-icon :icon="currentIcon" />
        </slot>
      </span>
    </span>
    <p v-if="slots.helper || showErrors" class="input__helper is-small-text">
      <span v-if="showErrors" class="input__errors">
        <template v-for="(error, index) in errors" :key="index">
          <span>{{ error }}</span>
        </template>
      </span>
      <slot name="helper"/>
    </p>
  </label>
</template>

<script setup>
import {
  ref,
  computed,
  useSlots,
  useAttrs,
} from 'vue';
import { BasicIcon } from '@/shared/ui/Icon';
import { fieldErrorsProp } from '@/shared/libs/helpers/errors';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  modelModifiers: Object,
  valid: {
    type: Boolean,
    required: true,
  },
  errors: fieldErrorsProp,
  withIcon: Boolean,
  icon: String,
});
const slots = useSlots();
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue', 'update:valid']);

const id = ref(`input-${(new Date()).getTime()}-${Math.random()}`);
const focus = ref(false);

const isSuccess = computed(() => props.valid && props.modelValue.length > 0);
const isError = computed(() => !props.valid);
const isFocused = computed(() => focus.value && !props.modelValue.length);
const isFilled = computed(() => focus.value && props.modelValue.length > 0);
const isDisabled = computed(() => attrs.disabled === '' || Boolean(attrs.disabled));
const currentIcon = computed(() => {
  if (isError.value) return 'feather/alert-triangle';
  if (isSuccess.value) return 'feather/check-circle';
  return props.icon || null;
});
const isWithIcon = computed(() => currentIcon.value || slots.icon);
const showErrors = computed(() => props.errors && props.errors.length && !props.valid);

function getText(nodes) {
  if (!Array.isArray(nodes)) return nodes;
  let text = '';
  nodes?.forEach((node) => {
    if (typeof node.children === 'string') text += node.children;
    else if (Array.isArray(node.children) && node.children.length) getText(node.children);
  });
  return text;
}

function updateValue(value) {
  emit('update:modelValue', value);
  if (attrs.pattern !== undefined) {
    const regexp = new RegExp(attrs.pattern);
    emit('update:valid', regexp.test(value));
  } else {
    emit('update:valid', true);
  }
}

function input(event) {
  if (props.modelModifiers && props.modelModifiers.lazy) return;
  updateValue(event.target.value);
}

function change(event) {
  if (!props.modelModifiers || !props.modelModifiers.lazy) return;
  updateValue(event.target.value);
}

function invalid() {
  emit('update:valid', false);
}
</script>

<style lang="stylus" scoped>
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/transition'

$color-success = #255F0B

.input
  display grid
  gap 4rem

  &__label
    color $gray-400

    transition color $transition-basic-short

  &__wrapper
    position relative

    padding 8rem 12rem

    background-color $white-primary
    border 1px solid $white-500
    border-radius 4rem

    transition border-color $transition-basic-short,
      outline .1s,
      outline-color $transition-basic-short
    cursor text

  &__tag
    width 100%

    flex-shrink 1

    background-color transparent
    color $gray-dark
    outline none
    cursor inherit

  &__icon
    position absolute
    top 50%
    right 12rem
    transform translateY(-50%)

    width 16rem

    display grid
    align-content center

    color $gray-200

  &__helper
    color $gray-200

    transition color $transition-basic-short

  &__errors
    margin-bottom 5rem

    display grid
    gap 2rem

  &:not(&--focused):not(&--disabled):hover &__wrapper
    border-color $lime-primary-default

  &--focused &__wrapper
    border-color $lime-primary-default
    outline 2px solid $lime-primary-default

  &--filled &__wrapper
    border-color $gray-400

  &--with-icon &__wrapper
    padding-right 40rem

  &--error:not(&--focused):not(&--disabled) &__wrapper
    border-color $danger-primary-default

  &--error &__icon
    color $danger-primary-default

  &--error &__helper
    color $danger-primary-default

  &--success:not(&--focused):not(&--disabled):hover &__wrapper
    border-color $color-success

  &--success&--filled &__wrapper
    border-color $color-success

  &--success &__icon
    color $color-success

  &--success &__helper
    color $color-success

  &--disabled &__wrapper
    background-color $white-50
    color $gray-disabled
    border-color $white-500

    cursor not-allowed

  &--disabled &__tag
    cursor not-allowed

  &--disabled &__label
  &--disabled &__helper
    color $gray-disabled
</style>
