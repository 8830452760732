import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useToastsStore = defineStore('toasts', () => {
  const toastsList = ref([]);

  const getToastsList = computed(() => toastsList.value);

  function addToast(message, type = 'success') {
    toastsList.value.push({ message, type });
    setTimeout(() => toastsList.value.shift(), 5000);
  }

  function removeToast(index) {
    toastsList.value.splice(index, 1);
  }

  return { getToastsList, addToast, removeToast };
});
