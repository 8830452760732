<template>
  <section id="book-call" class="home-book-call-section">
    <form @submit.prevent="bookCall" class="home-book-call-section__container">
      <div class="home-book-call-section__column">
        <h1 class="home-book-call-section__title">
          {{ t('title') }}
        </h1>
        <p class="home-book-call-section__text">
          {{ t('text') }}
        </p>
        <basic-button
          class="home-book-call-section__button on-desktop"
          type="submit"
          :loading="formLoading"
          linear
        >
          {{ t('form.buttons.submit') }}
        </basic-button>
      </div>
      <div class="home-book-call-section__column">
        <basic-input
          class="home-book-call-section__input"
          v-model="form.name.value"
          v-model:valid="form.name.valid"
          :errors="form.name.errors"
        >
          {{ t('form.fields.name.placeholder') }}
          <template #label>
            {{ t('form.fields.name.label') }}
          </template>
        </basic-input>
        <basic-input
          class="home-book-call-section__input"
          v-model="form.company_name.value"
          v-model:valid="form.company_name.valid"
          :errors="form.company_name.errors"
        >
          {{ t('form.fields.company-name.placeholder') }}
          <template #label>
            {{ t('form.fields.company-name.label') }}
          </template>
        </basic-input>
        <basic-input
          class="home-book-call-section__input"
          v-model="form.company_area_of_activity.value"
          v-model:valid="form.company_area_of_activity.valid"
          :errors="form.company_area_of_activity.errors"
        >
          {{ t('form.fields.company-area-of-activity.placeholder') }}
          <template #label>
            {{ t('form.fields.company-area-of-activity.label') }}
          </template>
        </basic-input>
        <basic-input
          class="home-book-call-section__input"
          v-model="form.email.value"
          v-model:valid="form.email.valid"
          :errors="form.email.errors"
        >
          {{ t('form.fields.email.placeholder') }}
          <template #label>
            {{ t('form.fields.email.label') }}
          </template>
        </basic-input>
        <basic-input
          class="book-call-popup__input"
          v-model="form.telegram_nickname.value"
          v-model:valid="form.telegram_nickname.valid"
          :errors="form.telegram_nickname.errors"
        >
          {{ t('form.fields.telegram-nickname.placeholder') }}
          <template #label>
            {{ t('form.fields.telegram-nickname.label') }}
          </template>
        </basic-input>
        <basic-button
          class="home-book-call-section__button on-mobile"
          type="submit"
          :loading="formLoading"
          linear
        >
          {{ t('form.buttons.submit') }}
        </basic-button>
      </div>
    </form>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { BasicButton } from '@/shared/ui/Button';
import { BasicInput } from '@/shared/ui/Input';
import {
  makeFormObjectByKeys,
  getValuesFromFormObjectByKeys,
  setErrorsToFormObject,
} from '@/shared/libs/helpers/form';
import { addToast } from '@/entities/Toast';
import { useLeadsStore } from '@/entities/Lead';
import messages from '../model/homeBookCallSectionMessages.json';

const { t } = useI18n({ messages });
const leadsStore = useLeadsStore();

const formKeys = ref([
  'name',
  'company_name',
  'company_area_of_activity',
  'email',
  'telegram_nickname',
]);
const form = ref(makeFormObjectByKeys(formKeys.value));
const formLoading = ref(false);

async function bookCall() {
  formLoading.value = true;

  const { result, errors } = await leadsStore.bookCall(
    getValuesFromFormObjectByKeys(form.value, formKeys.value),
  );

  if (result) addToast(t('toasts.success'));
  else setErrorsToFormObject(form.value, errors);

  formLoading.value = false;
}
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/devices'

.home-book-call-section
  padding 200rem 72rem 100rem

  &__container
    padding 60rem 40rem

    display grid
    grid-template-columns auto 1fr
    gap 80rem

    background linear-gradient(90deg, rgba(20, 168, 0, .5) 0%, rgba(8, 66, 0, .3) 55%,
      rgba(8, 66, 0, .3) 65%, rgba(16, 134, 0, .5) 100%)
    border 1px solid #B1B1B1
    border-radius 30rem

  &__column
    display flex
    flex-direction column

    &:nth-child(2)
      gap 16rem

  &__title
    color #2DE115

    text-transform uppercase

  &__text
    margin-top 8rem
    width 386rem
    height auto

    font-size 25rem
    font-weight 300
    line-height 27rem

  &__button
    margin auto 0 10rem
    height 53rem

  &__input
    width 100%

  @media all and (max-width $mobile_media)
    padding 120rem 16rem

    &__container
      padding 28rem 18rem 36rem

      grid-template-columns 1fr
      gap 16rem

    &__title
      text-align center

    &__text
      margin 6rem auto 0
      width 270rem

      font-size 12rem
      line-height 14rem

      text-align center

    &__button
      margin 16rem 26rem 0
      height max-content
</style>
