<template>
  <section class="home-tariffs-section">
    <div class="home-tariffs-section__container">
      <h1 class="home-tariffs-section__title">
        {{ t('title') }}
      </h1>
      <div class="home-tariffs-section__list">
        <tariff-card
          v-for="(tariffModel, index) in tariffsStore.getTariffsList"
          :key="index"
          class="home-tariffs-section__card"
          :tariff-model="tariffModel"
          @buy="pushToPrecrm"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { TariffCard, useTariffsStore } from '@/entities/Tariff';
import messages from '../model/homeTariffsSectionMessages.json';

const { t } = useI18n({ messages });
const tariffsStore = useTariffsStore();

function pushToPrecrm() {
  window.location = `${process.env.VUE_APP_PRECRM_URL}/dashboard/pricing`;
}

await tariffsStore.fetchAllTariffs();
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/devices'

.home-tariffs-section
  padding 100rem 72rem

  &__container
    display grid
    gap 46rem

  &__title
    color #2DE115

    text-transform uppercase
    text-align center

  &__list
    display grid
    grid-template-columns repeat(3, 1fr)
    gap 24rem

  &__card
    transition transform $transition-basic-short

    &:hover
      transform translateY(-10rem)

  @media all and (max-width $mobile_media)
    padding 60rem 16rem

    &__container
      gap 32rem

    &__list
      grid-template-columns 1fr

</style>
