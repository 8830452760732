import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { apiInstance } from '@/shared/api';
import { httpBuildQuery } from '@/shared/libs/helpers/query';

export const useTariffsStore = defineStore('tariffs', () => {
  const tariffsList = ref(null);

  const getTariffsList = computed(() => tariffsList.value);

  function findByName(name) {
    if (!tariffsList.value) return null;
    return tariffsList.value.find((tariff) => tariff.name === name);
  }

  async function fetchAllTariffs(once = true) {
    if (once && tariffsList.value) return;

    tariffsList.value = await apiInstance.get('/tariffs/get-all')
      .then((axiosResponse) => axiosResponse.data.list)
      .catch(() => []);
  }

  async function checkPromoCode(promoCode) {
    return apiInstance.post('/tariffs/check-promo-code', { promo_code: promoCode })
      .then((axiosResponse) => axiosResponse.data.result)
      .catch(() => false);
  }

  async function buyTariff(data) {
    return apiInstance.post('/tariffs/buy', data)
      .then((axiosResponse) => axiosResponse.data)
      .catch(() => ({ result: false }));
  }

  async function checkTariffInvoice(invoiceRef) {
    return apiInstance.get(
      httpBuildQuery('/companies/billing/get-tariff-invoice-info', { ref: invoiceRef }),
    )
      .then(() => true)
      .catch(() => false);
  }

  return {
    getTariffsList,
    findByName,
    fetchAllTariffs,
    checkPromoCode,
    buyTariff,
    checkTariffInvoice,
  };
});
