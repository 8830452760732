import { createApp } from 'vue';
import App from './app/App.vue';
import { i18nInstance } from './shared/config/lang';
import { piniaInstance } from './shared/config/store';
import { router } from './app/router';

createApp(App)
  .use(i18nInstance)
  .use(piniaInstance)
  .use(router)
  .mount('#app');
