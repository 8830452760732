<template>
  <popup-template ref="bookCallPopup" class="book-call-popup">
    <form @submit.prevent="bookCall" class="book-call-popup__container">
      <div class="book-call-popup__column">
        <h1 class="book-call-popup__title">
          {{ t('title') }}
        </h1>
        <p class="book-call-popup__text">
          {{ t('text') }}
        </p>
      </div>
      <div class="book-call-popup__column">
        <basic-input
          class="book-call-popup__input"
          v-model="form.name.value"
          v-model:valid="form.name.valid"
          :errors="form.name.errors"
        >
          {{ t('form.fields.name.placeholder') }}
          <template #label>
            {{ t('form.fields.name.label') }}
          </template>
        </basic-input>
        <basic-input
          class="book-call-popup__input"
          v-model="form.company_name.value"
          v-model:valid="form.company_name.valid"
          :errors="form.company_name.errors"
        >
          {{ t('form.fields.company-name.placeholder') }}
          <template #label>
            {{ t('form.fields.company-name.label') }}
          </template>
        </basic-input>
        <basic-input
          class="book-call-popup__input"
          v-model="form.company_area_of_activity.value"
          v-model:valid="form.company_area_of_activity.valid"
          :errors="form.company_area_of_activity.errors"
        >
          {{ t('form.fields.company-area-of-activity.placeholder') }}
          <template #label>
            {{ t('form.fields.company-area-of-activity.label') }}
          </template>
        </basic-input>
        <basic-input
          class="book-call-popup__input"
          v-model="form.email.value"
          v-model:valid="form.email.valid"
          :errors="form.email.errors"
        >
          {{ t('form.fields.email.placeholder') }}
          <template #label>
            {{ t('form.fields.email.label') }}
          </template>
        </basic-input>
        <basic-input
          class="book-call-popup__input"
          v-model="form.telegram_nickname.value"
          v-model:valid="form.telegram_nickname.valid"
          :errors="form.telegram_nickname.errors"
        >
          {{ t('form.fields.telegram-nickname.placeholder') }}
          <template #label>
            {{ t('form.fields.telegram-nickname.label') }}
          </template>
        </basic-input>
        <basic-button
          class="book-call-popup__button"
          type="submit"
          :loading="formLoading"
          linear
        >
          {{ t('form.buttons.submit') }}
        </basic-button>
      </div>
    </form>
  </popup-template>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { BasicButton } from '@/shared/ui/Button';
import { BasicInput } from '@/shared/ui/Input';
import { PopupTemplate } from '@/shared/ui/Popup';
import {
  makeFormObjectByKeys,
  getValuesFromFormObjectByKeys,
  setErrorsToFormObject,
} from '@/shared/libs/helpers/form';
import { addToast } from '@/entities/Toast';
import { useLeadsStore } from '@/entities/Lead';
import messages from '../model/bookCallPopupMessages.json';

const { t } = useI18n({ messages });
const leadsStore = useLeadsStore();

const bookCallPopup = ref(null);
const formKeys = ref([
  'name',
  'company_name',
  'company_area_of_activity',
  'email',
  'telegram_nickname',
]);
const form = ref(null);
const formLoading = ref(false);

function open() {
  form.value = makeFormObjectByKeys(formKeys.value);
  bookCallPopup.value.open();
}

function close() {
  bookCallPopup.value.close();
}

async function bookCall() {
  formLoading.value = true;

  const { result, errors } = await leadsStore.bookCall(
    getValuesFromFormObjectByKeys(form.value, formKeys.value),
  );

  if (result) {
    addToast(t('toasts.success'));
    close();
  } else {
    setErrorsToFormObject(form.value, errors);
  }

  formLoading.value = false;
}

defineExpose({
  open,
});
</script>

<style lang="stylus">
@require '@/shared/styles/utils/devices'

.book-call-popup
  width 820rem

  @media all and (max-width $mobile_media)
    width 330rem
</style>

<style scoped lang="stylus">
@require '@/shared/styles/utils/devices'

.book-call-popup
  &__container
    display grid
    grid-template-columns 1fr
    gap 24rem

  &__column
    display flex
    flex-direction column

    &:nth-child(2)
      gap 16rem

  &__title
    color #2DE115

    text-align center

  &__text
    margin 6rem auto 0

    font-size 24rem
    font-weight 300
    line-height 26rem

    text-align center

  &__button
    margin 16rem auto 0
    width 345rem

  &__input
    width 100%

  @media all and (max-width $mobile_media)
    &__container
      gap 16rem

    &__title
      font-size 18rem
      line-height 20rem

    &__text
      width 270rem

      font-size 12rem
      line-height 14rem

    &__button
      margin 16rem 26rem 0
      width auto
</style>
