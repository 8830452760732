<template>
  <basic-icon class="basic-logo" :icon="currentIcon" />
</template>

<script setup>
import { computed } from 'vue';
import { BasicIcon } from '@/shared/ui/Icon';

const props = defineProps({
  withText: Boolean,
});

const currentIcon = computed(() => (props.withText ? 'other/logo' : 'other/logo-icon'));
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'

.basic-logo
  color $lime-primary-default
</style>
