import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { defineStore } from 'pinia';
import moment from 'moment';
import { getCookieParam, setCookieParam } from '@/shared/libs/cookie';
import { apiInstance } from '@/shared/api';

export const useLanguageStore = defineStore('language', () => {
  const i18n = useI18n();

  const currentLanguage = ref(getCookieParam('currentLanguage') || null);
  const languagesList = ref(null);

  const getCurrentLanguage = computed(() => currentLanguage.value);
  const getLanguagesList = computed(() => languagesList.value);

  moment.locale(currentLanguage.value);

  function setCurrentLanguage(language) {
    currentLanguage.value = language;
    i18n.locale.value = language;
    moment.locale(language);
    setCookieParam('currentLanguage', language);
  }

  function setFallbackLanguage(language) {
    i18n.fallbackLocale.value = language;
  }

  async function fetchAllLanguages(once = true) {
    if (once && languagesList.value) return;

    const response = await apiInstance.get('/languages/get-all')
      .then((axiosResponse) => axiosResponse.data)
      .catch(() => ({ list: ['uk'], default: 'uk' }));

    languagesList.value = response.list;
    i18n.availableLocales.value = response.list;
    setCurrentLanguage(currentLanguage.value || response.default);
    setFallbackLanguage(response.default);
  }

  return {
    getCurrentLanguage,
    getLanguagesList,
    setCurrentLanguage,
    fetchAllLanguages,
  };
});
