<template>
  <footer class="home-footer">
    <picture class="home-footer__image">
      <source
        type="image/webp"
        :srcset="require('@/shared/assets/images/home-page/footer__photo.webp')"
      >
      <source
        type="image/png"
        :srcset="require('@/shared/assets/images/home-page/footer__photo.png')"
      >
      <img
        :src="require('@/shared/assets/images/home-page/footer__photo.png')"
        alt="photo"
        width="100%"
      >
    </picture>
    <div class="home-footer__row home-footer__row--content">
      <div class="home-footer__wrapper">
        <div class="home-footer__contacts">
          <p>{{ t('contacts') }}</p>
          <!-- eslint-disable-next-line -->
          <a
            href="https://t.me/vadumtss"
            class="home-footer__link"
            target="_blank"
            aria-hidden="true"
          >
            <basic-icon class="home-footer__icon" icon="other/telegram-logo" />
          </a>
          <!-- eslint-disable-next-line -->
          <a
            href="https://www.instagram.com/markiv.vadim"
            class="home-footer__link"
            target="_blank"
            aria-hidden="true"
          >
            <basic-icon class="home-footer__icon" icon="other/instagram-logo" />
          </a>
        </div>
        <basic-button
          class="home-footer__button"
          linear
          @click="emit('openSendQuestionPopup')"
        >
          {{ t('buttons.question') }}
        </basic-button>
      </div>
      <basic-logo class="home-footer__logo on-desktop" />
    </div>
    <div class="home-footer__row home-footer__row--bottom">
      <img
        class="home-footer__icon"
        :src="require('@/shared/assets/icons/other/mastercard-logo.png')"
        alt="mastercard-logo"
      />
      <img
        class="home-footer__icon"
        :src="require('@/shared/assets/icons/other/visa-logo.png')"
        alt="visa-logo"
      />
      <a
        :href="`${precrmUrl}/privacy-policy`"
        class="home-footer__link home-footer__link--text"
        target="_blank"
      >
        {{ t('privacy-policy') }}
      </a>
      <div class="home-footer__copyright on-desktop">
        <p>@2024</p>
        <p>ThepartmentS</p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { BasicIcon } from '@/shared/ui/Icon';
import { BasicLogo } from '@/shared/ui/Logo';
import { BasicButton } from '@/shared/ui/Button';
import messages from '../model/homeFooterMessages.json';

const precrmUrl = process.env.VUE_APP_PRECRM_URL;

const { t } = useI18n({ messages });

const emit = defineEmits(['openSendQuestionPopup']);
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/devices'

.home-footer
  position relative

  color $white-primary

  &__image
    position absolute
    left -50rem
    bottom 0

    img
      width 630rem

  &__row
    padding-left 560rem

    display grid
    align-items center

    &--content
      padding-top 130rem
      padding-bottom 130rem

      grid-template-columns repeat(2, auto)
      gap 160rem
      justify-content center

    &--bottom
      padding-top 30rem
      padding-bottom 30rem

      grid-template-columns 1fr 1fr 4fr 2fr 1fr

      background-color #151515
      border-top 1px solid #555555

  &__link
    text-align center

    transition opacity $transition-basic-short

    &--text
      color #8F8F8F

    &:hover
      opacity .7

  &__wrapper
    display grid
    gap 48rem

  &__contacts
    display flex
    align-items center
    gap 70rem

    font-size 22rem
    line-height 24rem

  &__button
    width 325rem

  &__logo
    width 120rem
    height 120rem

  &__link &__icon
    width 30rem
    height 30rem

  &__row--bottom &__icon

    &:nth-child(1)
      width 50rem

    &:nth-child(2)
      width 70rem

  &__copyright
    color #8F8F8F

    text-align right

  @media all and (max-width $mobile_media)
    &__image
      position relative

      img
        width 500rem

    &__row
      padding-left 0

      &--content
        padding 32rem 60rem

        grid-template-columns 1fr

        border-top 1px solid #555555

      &--bottom
        padding 12rem 18rem

        display flex
        gap 12rem

    &__row--bottom &__icon

      &:nth-child(1)
        width 27rem

      &:nth-child(2)
        width 47rem

    &__row--bottom &__link
      margin-left auto

    &__wrapper
    &__contacts
      gap 16rem

    &__contacts &__link:nth-child(2)
      margin-left auto
</style>
