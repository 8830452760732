export const tariffModelValidator = (model) => (
  typeof model === 'object'
  && model !== null
  && typeof model.name === 'string'
  && typeof model.price === 'number'
  && typeof model.max_leadgen_amount === 'number'
  && typeof model.title === 'string'
  && typeof model.description === 'string'
  && ['undefined', 'string'].includes(typeof model.tip)
  && typeof model.button === 'string'
  && Array.isArray(model.features)
  && !model.features.some((feature) => !(
    typeof feature === 'object'
    && feature !== null
    && typeof feature.type === 'string'
    && (
      (
        ['paragraph', 'feature'].includes(feature.type)
        && typeof feature.text === 'string'
      ) || (
        feature.type === 'list'
        && Array.isArray(feature.list)
        && !feature.list.some((item) => !(typeof item === 'string'))
      )
    )
  ))
  && !model.all_features.some((feature) => !(
    typeof feature === 'object'
    && feature !== null
    && typeof feature.type === 'string'
    && (
      (
        feature.type === 'feature'
        && typeof feature.text === 'string'
      ) || (
        feature.type === 'list'
        && Array.isArray(feature.list)
        && !feature.list.some((item) => !(typeof item === 'string'))
      )
    )
  ))
);
