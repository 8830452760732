<template>
  <div
    class="tariff-card"
    :class="{
      'tariff-card--dark': !tariffModel.tip,
    }"
  >
    <div class="tariff-card__head">
      <div class="tariff-card__head-wrapper">
        <h2 class="tariff-card__title">{{ tariffModel.title }}</h2>
        <p v-if="tariffModel.tip" class="tariff-card__tip">{{ tariffModel.tip }}</p>
      </div>
      <p class="tariff-card__description">{{ tariffModel.description }}</p>
    </div>
    <div class="tariff-card__price">
      <span>${{ tariffModel.price }}</span> / {{ t('subscription-duration') }}
    </div>
    <basic-button
      v-if="!active"
      class="tariff-card__button"
      primary
      :disabled="disabled"
      @click="$emit('buy')"
    >
      {{ tariffModel.button }}
    </basic-button>
    <div class="tariff-card__features">
      <div
        v-for="(feature, index) in tariffModel[active ? 'all_features' : 'features']"
        :key="index"
        class="tariff-card__feature"
        :class="{
          'tariff-card__feature--paragraph': feature.type === 'paragraph',
          'tariff-card__feature--list': feature.type === 'list',
        }"
      >
        <template v-if="feature.type === 'list'">
          <li v-for="item in feature.list" :key="item" class="tariff-card__feature-item">
            {{ item }}
          </li>
        </template>
        <template v-else>
          <div v-if="feature.type === 'feature'" class="tariff-card__feature-icon">
            <basic-icon icon="phosphor/check" />
          </div>
          <p class="tariff-card__feature-text">{{ feature.text }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { BasicButton } from '@/shared/ui/Button';
import { BasicIcon } from '@/shared/ui/Icon';
import { tariffModelValidator } from '../libs/validators';
import messages from '../model/tariffCardMessages.json';

defineProps({
  tariffModel: {
    type: Object,
    required: true,
    validator: (tariffModel) => tariffModelValidator(tariffModel),
  },
  active: Boolean,
  disabled: Boolean,
});
defineEmits(['buy']);

const { t } = useI18n({ messages });
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/devices'

.tariff-card
  padding 40rem 24rem

  display grid
  align-content flex-start
  gap 20rem

  background $white-primary
  box-shadow: 0 10rem 25rem 0 rgba($gray-dark, .1)
  border 1px solid $lime-primary-default
  border-radius 8rem

  &__head
    display grid
    gap 10rem

  &__head-wrapper
    display flex
    justify-content space-between

  &__title
    color $neutral-800

    font-size 18rem
    font-weight 700
    line-height 20rem

  &__description
    color $gray-primary

  &__tip
    height max-content
    padding 4rem 8rem

    background-color $lime-primary-default
    color $gray-dark
    border-radius 8rem

    font-size 11rem
    line-height 13rem

  &__price
    display flex
    align-items center
    gap 8rem

    color $neutral-600

    span
      color $neutral-800

      font-size 64rem
      font-weight 600
      line-height 88rem

  &__button
    margin-bottom 20rem
    padding 16rem

    border-radius 4rem

  &__features
    display grid
    gap 12rem

  &__feature
    &:not(&--paragraph):not(&--list)
      display grid
      grid-auto-flow column
      justify-content flex-start
      align-items center
      gap 16rem

      color $neutral-800

    &--paragraph
      color $gray-dark

      font-weight 500

    &--list
      padding-left 50rem

      color $neutral-800

      list-style-type disc

  &__feature-icon
    width 32rem
    height 32rem

    display flex
    justify-content center
    align-items center

    background-color $lime-primary-default
    border-radius 100rem

    svg
      width 16rem
      height 16rem

      color $white-primary

  &--dark
    background-color $gray-dark
    box-shadow: 0 10rem 25rem 0 rgba($gray-dark, .3)

  &--dark &__title
    color $white-primary

  &--dark &__title
    color $white-primary

  &--dark &__description
    color $neutral-50

  &--dark &__price
    color $white-primary

    span
      color $neutral-50

  &--dark &__feature
    &:not(&--paragraph)
      color $neutral-50

    &--paragraph
      color $white-primary

  @media all and (max-width $mobile_media)
    &__title
      font-size 14rem
      line-height 16rem

    &__tip
      font-size 9rem
      line-height 11rem

    &__button
      padding 12rem
</style>
