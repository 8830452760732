<template>
  <div :class="`basic-toast basic-toast--${type}`">
    <basic-icon class="basic-toast__icon" :icon="currentIcon" />
    <p class="basic-toast__message is-big-text">
      <slot />
    </p>
    <basic-icon
      class="basic-toast__close"
      icon="feather/x"
      @click="$emit('close')"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { BasicIcon } from '@/shared/ui/Icon';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator(type) {
      return type === 'success' || type === 'warning';
    },
  },
});

const currentIcon = computed(() => {
  if (props.type === 'success') return 'feather/check-circle';
  return 'feather/alert-triangle';
});
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/transition'

.basic-toast
  position relative

  width 100%
  padding 16rem 40rem 16rem 24rem

  display flex
  gap 8rem

  border-radius 8rem

  transition background-color $transition-basic-short

  &__icon
    min-width 24rem
    width 24rem
    height 24rem

    color $white-primary

  &__message
    color $white-primary

  &__close
    position absolute
    top 12rem
    right 12rem

    width 16rem
    height 16rem

    color $white-primary

    cursor pointer

  &--success
    background-color $lime-primary-default

    &:hover
      background-color $lime-primary-hover

  &--warning
    background-color $danger-secondary-hover

    &:hover
      background-color $danger-secondary-pressed
</style>
