function getCookieParam(name) {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(name))
    ?.split('=')[1];
}

function setCookieParam(name, value, expires = 'Fri, 31 Dec 9999 23:59:59 GMT') {
  document.cookie = `${name}=${value}; expires=${expires}; SameSite=Lax`;
}

function resetCookieParam(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax`;
}

export { getCookieParam, setCookieParam, resetCookieParam };
