<template>
  <inline-svg
    class="basic-icon"
    :src="iconSrc"
  />
</template>

<script setup>
import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
});

const iconSrc = computed(() => {
  try {
    return require(`@/shared/assets/icons/${props.icon}.svg`); // eslint-disable-line
  } catch (err) {
    return require('@/shared/assets/icons/feather/file-minus.svg'); // eslint-disable-line
  }
});
</script>
