<template>
  <section class="home-system-section">
    <div class="home-system-section__container">
      <div class="home-system-section__heading">
        <h1 class="home-system-section__title">
          {{ t('title') }}
        </h1>
        <p class="home-system-section__description">
          {{ t('description') }}
        </p>
      </div>
      <div class="home-system-section__content">
        <div
          v-for="(stage, index) in tm('stages')"
          :key="index"
          class="home-system-section__stage"
        >
          <div class="home-system-section__stage-body">
            <basic-icon
              class="home-system-section__stage-body-shadow not-fill not-stroke"
              :class="{
                'on-desktop': index === 2,
              }"
              :icon="`other/lime-stage-shadow-${index === 2 ? 'left' : 'right' }`"
            />
            <basic-icon
              v-if="index === 2"
              class="home-system-section__stage-body-shadow not-fill not-stroke on-mobile"
              icon="other/lime-stage-shadow-right"
            />
            <span class="home-system-section__stage-body-line" />
            <basic-icon
              class="home-system-section__stage-number not-fill not-stroke"
              :icon="`other/${index+1}-3d`"
            />
            <div class="home-system-section__stage-wrapper">
              <h3 class="home-system-section__stage-title">
                {{ stage.title }}
              </h3>
              <p class="home-system-section__stage-description">
                {{ stage.description }}
              </p>
            </div>
          </div>
          <div v-if="index !== 2" class="home-system-section__stage-shadows on-desktop">
            <basic-icon
              v-for="key in 3"
              :key="key"
              class="home-system-section__stage-shadow not-fill not-stroke"
              :icon="`other/green-stage-shadow-${index === 1 ? 'left' : 'right' }`"
            />
          </div>
          <picture class="home-system-section__image">
            <source
              type="image/webp"
              :srcset="
                require(`@/shared/assets/images/home-page/system-section__stage-${index+1}.webp`)"
            >
            <source
              type="image/png"
              :srcset="
                require(`@/shared/assets/images/home-page/system-section__stage-${index+1}.png`)"
            >
            <img
              :src="
                require(`@/shared/assets/images/home-page/system-section__stage-${index+1}.png`)"
              alt="photo"
              width="100%"
            >
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { BasicIcon } from '@/shared/ui/Icon';
import messages from '../model/homeSystemSectionMessages.json';

const { t, tm } = useI18n({ messages });
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/z-index'
@require '@/shared/styles/utils/devices'

.home-system-section
  padding 200rem 72rem 0

  &__container
    display grid
    gap 90rem

  &__heading
    display grid
    gap 10rem

    text-align center

  &__title
    color #2DE115

    text-transform uppercase

  &__description
    font-size 25rem
    line-height 27rem

    font-weight 300

  &__content
    display grid
    gap 250rem

  &__stage
    position relative

    display flex
    justify-content space-between

  &__stage:nth-child(2)
    flex-direction row-reverse

  &__stage-body
    position relative
    z-index $z-index-basic-1

    height max-content

    display flex
    align-items center
    gap 16rem

  @media all and (min-width $mobile_media)
    &__stage:nth-child(2) &__stage-body
      flex-direction row-reverse

  &__stage-number
    position relative

    height 192rem

  &__stage-body-shadow
    position absolute
    bottom -120rem

    width 420rem

  @media all and (min-width $mobile_media)
    &__stage:nth-child(1) &__stage-body-shadow
      left -40rem

    &__stage:nth-child(2) &__stage-body-shadow
      right -10rem

    &__stage:nth-child(3) &__stage-body-shadow
      left -10rem

  &__stage-body-line
    position absolute

    height 1rem

    background-color #14A800

    &::after
      content ''
      position absolute
      top 0
      right 0
      transform translate(50%, -50%)

      width 16rem
      height 16rem

      background-color #14A800
      border-radius 16rem

  @media all and (min-width $mobile_media)
    &__stage:nth-child(1) &__stage-body-line
      top 175rem
      right 0
      transform translateX(100%)

      width 437rem

    &__stage:nth-child(2) &__stage-body-line
      top 140rem
      left 80rem
      transform translateX(-100%)

      width 408rem

      &::after
        left 0
        transform translate(-50%, -50%)

    &__stage:nth-child(3) &__stage-body-line
      top 175rem
      right 120rem
      transform translateX(100%)

      width 420rem

  &__stage-wrapper
    position relative

    display grid
    gap 10rem

  @media all and (min-width $mobile_media)
    &__stage:nth-child(1) &__stage-wrapper
      width 220rem

    &__stage:nth-child(2) &__stage-wrapper
      width 300rem

      text-align right

    &__stage:nth-child(3) &__stage-wrapper
      width 335rem

  &__stage-shadows
    position absolute

    display grid
    grid-template-columns repeat(5, 240rem)
    grid-template-rows repeat(5, 130rem)

  &__stage:nth-child(1) &__stage-shadows
    bottom -145%
    left -5%

  &__stage:nth-child(2) &__stage-shadows
    bottom -150%
    left -3%

  &__stage-shadow
    min-width 420rem

    &:nth-child(1)
      grid-row-start 2

    &:nth-child(2)
      grid-row-start 3

    &:nth-child(3)
      grid-row-start 4

  &__stage:nth-child(1) &__stage-shadow

    &:nth-child(1)
      grid-column-start 2

    &:nth-child(2)
      grid-column-start 3

    &:nth-child(3)
      grid-column-start 4

  &__stage:nth-child(2) &__stage-shadow

    &:nth-child(1)
      grid-column-start 4

    &:nth-child(2)
      grid-column-start 3

    &:nth-child(3)
      grid-column-start 2

  &__image
    position relative

    width 520rem

  @media all and (max-width $mobile_media)
    padding 32rem 16rem 0

    &__container
      gap 40rem

    &__description
      margin 0 auto
      max-width 280rem

      font-size 12rem
      line-height 14rem

    &__content
      gap 60rem

    &__stage
      display grid
      justify-content center
      gap 100rem

    &__stage-body
      margin 0 auto

      gap 12rem

    &__stage:nth-child(1) &__stage-wrapper
      width 170rem

    &__stage:nth-child(2) &__stage-wrapper
      width 172rem

    &__stage:nth-child(3) &__stage-wrapper
      width 213rem

    &__stage-number
      margin-left 30rem
      height 110rem

    &__stage-body-shadow
      width 280rem
      left 50%
      bottom -68rem
      transform translateX(-50%)

    &__stage-body-line
      top 175rem
      left 50%
      transform translate(100%, -.5rem)

      width 1rem !important
      height 34rem

      &::after
        background-color $white-primary

    &__stage-title
      font-size 14rem
      line-height 16rem

    &__stage-description
      font-size 12rem
      line-height 14rem

    &__stage &__stage-description
      font-size 10rem
      line-height 12rem

    &__image
      width 320rem
</style>
