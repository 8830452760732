<template>
  <component
    v-bind="$props"
    :is="component"
    class="button"
    :class="{
      'button--primary': primary,
      'button--linear': linear,
      'button--secondary': secondary,
      'button--big': big,
      'is-big-text': big,
      'button--icon': isOnlyIcon,
      'button--loading': loading,
    }"
  >
    <basic-loader
      v-if="loading"
      class="button__loader"
      :primary="!primary && !secondary && !linear"
      :secondary="secondary"
      :style="{
        '--circle-width': '4rem',
      }"
    />
    <template v-else>
      <slot/>
      <basic-icon v-if="icon" :icon="icon" />
    </template>
  </component>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import { RouterLink } from 'vue-router';
import { BasicIcon } from '@/shared/ui/Icon';
import { BasicLoader } from '@/shared/ui/Loader';

const props = defineProps({
  primary: Boolean,
  linear: Boolean,
  secondary: Boolean,
  big: Boolean,
  icon: String,
  loading: Boolean,
  href: String,
  to: RouterLink,
});
const slots = useSlots();

const isOnlyIcon = Boolean(slots.default) === false && Boolean(props.icon);

const component = computed(() => {
  if (props.to) return 'router-link';
  if (props.href) return 'a';
  return 'button';
});
</script>

<style lang="stylus" scoped>
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/devices'

$color-gray-disabled = #B9B9B9
$color-white-disabled = #F0F0F0

.button
  position relative

  padding 18rem 36rem
  min-width 54rem

  display flex
  justify-content center
  align-items center
  gap 4rem

  background-color transparent
  color $lime-primary-default
  border-radius 35rem
  outline 0 solid transparent

  font-weight 500

  text-transform uppercase

  transition color $transition-basic-short,
    background-position $transition-basic-short,
    background-color $transition-basic-short,
    border .1s,
    border-color $transition-basic-short,
    outline .1s,
    outline-color $transition-basic-short
  cursor pointer
  user-select none

  svg
    width 16rem
    height 16rem

    color $lime-primary-default

    transition $transition-basic-short

  &__loader
    width 30rem !important

    &::after
      width 30rem !important

  &--loading
    padding 4rem 16rem

  &:disabled
    cursor not-allowed

  &:not(&--primary):not(&--linear):not(&--secondary)

    &:hover
    &:hover svg
      color $lime-primary-hover

    &:focus
    &:focus svg
      color $lime-primary-focused
      outline 1px solid rgba($lime-primary-default, .3)

    &:active
    &:active svg
      color $lime-primary-pressed

    &:disabled
    &:disabled svg
      color $color-gray-disabled

  &--icon
    padding 8rem
    aspect-ratio 1 / 1
    scale 0.9

    svg
      width 20rem
      height 20rem

    &::after
      content ''
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

      width calc(100% + 8rem)
      height calc(100% + 8rem)

      border 1px solid transparent
      border-radius 50%

  &--icon&--big svg
    width 24rem
    height 24rem

  &--primary
    background-color $lime-primary-default
    color $white-primary

    svg
      color $white-primary

    &:hover
      background-color $lime-primary-hover

    &:focus
      background-color $lime-primary-focused
      border-color $lime-secondary-focused
      outline 2px solid $lime-secondary-focused

    &:active
      background-color $lime-primary-pressed

    &:disabled
      background-color $color-white-disabled
      color $color-gray-disabled

      svg
        color $gray-400

  &--linear
    background: linear-gradient(180deg, rgba(22, 78, 15, 1), rgba(45, 225, 21, 1),
      rgba(45, 225, 21, 1), rgba(22, 78, 15, 1));
    background-size: 200% 200%;
    background-position 100% 100%

    color #000

    svg
      color #000

    &::after
      border-color $lime-primary-default

    &:hover
      background-position 50% 50%

    &:focus
      background-color $danger-primary-focused
      border-color rgba($danger-primary-focused, .2)
      outline 2px solid $lime-primary-default

    &:active
      background-position 0 0

    &:disabled
      background-color $color-white-disabled
      color $color-gray-disabled
      border-color #C9C9C9

      svg
        color $gray-400

  &--secondary
    color $white-primary
    border 1px solid $gray-300

    svg
      color $gray-primary

    &:hover
      background-color $lime-primary-default
      border-color $lime-primary-default

    &:focus
      background-color $lime-primary-default
      outline 2px solid $lime-primary-default

    &:active
      background-color $lime-primary-pressed
      border-color $lime-primary-pressed

    &:disabled
      background-color $color-white-disabled
      color $color-gray-disabled
      border-color #C9C9C9

      svg
        color $gray-400

  @media all and (max-width $mobile_media)
    padding 8rem 16rem

    &--linear
      padding 12rem 24rem

    &--linear&--icon
      padding 8rem
</style>
