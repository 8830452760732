const errorsValidator = (errors) => (
  typeof errors === 'object'
  && errors !== null
  && !Object.values(errors).some((field) => !(
    Array.isArray(field)
    && !field.some((error) => !(typeof error === 'string'))
  ))
);

const fieldErrorsValidator = (errors) => (
  !errors.some((error) => !(typeof error === 'string'))
);

const fieldErrorsProp = {
  type: Array,
  validator: (errors) => fieldErrorsValidator(errors),
};

export { errorsValidator, fieldErrorsValidator, fieldErrorsProp };
