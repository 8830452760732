<template>
  <main class="home">
    <toast-container class="home__toast-container"/>
    <div class="home__shadows">
      <img
        class="home__shadow"
        :src="require('@/shared/assets/icons/other/shadow.svg')"
        alt="shadow"
      >
    </div>
    <div class="home__textures">
      <picture class="home__texture">
        <source
          type="image/webp"
          :srcset="require('@/shared/assets/images/home-page/top-texture.webp')"
        >
        <source
          type="image/png"
          :srcset="require('@/shared/assets/images/home-page/top-texture.png')"
        >
        <img
          :src="require('@/shared/assets/images/home-page/top-texture.png')"
          alt="shadow"
          width="100%"
        >
      </picture>
      <picture class="home__texture">
        <source
          type="image/webp"
          :srcset="require('@/shared/assets/images/home-page/bottom-texture.webp')"
        >
        <source
          type="image/png"
          :srcset="require('@/shared/assets/images/home-page/bottom-texture.png')"
        >
        <img
          :src="require('@/shared/assets/images/home-page/bottom-texture.png')"
          alt="shadow"
          width="100%"
        >
      </picture>
    </div>
    <home-header @open-book-call-popup="bookCallPopupElement.open()" class="home__header" />
    <home-hero-section @open-book-call-popup="bookCallPopupElement.open()" class="home__section" />
    <home-analytics-section class="home__section" />
    <home-system-section class="home__section" />
    <home-book-call-section class="home__section" />
    <home-tariffs-section class="home__section" />
    <home-footer @open-send-question-popup="sendQuestionPopupElement.open()" class="home__footer" />
    <transition name="home__button-up">
      <basic-button
        v-if="showButtonUp"
        class="home__button-up"
        href="#"
        icon="other/arrow-up"
        big
        linear
      />
    </transition>
    <book-call-popup ref="bookCallPopupElement" />
    <send-question-popup ref="sendQuestionPopupElement" />
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { BasicButton } from '@/shared/ui/Button';
import { ToastContainer } from '@/entities/Toast';
import { BookCallPopup } from '@/features/BookCall';
import { SendQuestionPopup } from '@/features/SendQuestion';
import {
  HomeHeader,
  HomeHeroSection,
  HomeAnalyticsSection,
  HomeSystemSection,
  HomeBookCallSection,
  HomeTariffsSection,
  HomeFooter,
} from '@/widget/Home';

const showButtonUp = ref(false);
const bookCallPopupElement = ref(null);
const sendQuestionPopupElement = ref(null);

window.addEventListener('scroll', () => {
  showButtonUp.value = window.scrollY - window.screen.height + 200 > 0;
});
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/z-index'
@require '@/shared/styles/utils/devices'

.home
  position relative

  margin-top -82rem
  padding-top 82rem
  width 100%
  height 100%
  overflow clip

  background #000

  &__toast-container
    position fixed !important
    top 20rem
    left 50%
    z-index $z-index-top

  &::before
    content ''
    position absolute
    top 0
    left 0

    width 100%
    height 100%

    background-image url('@/shared/assets/images/home-page/background.png')
    background-repeat repeat-y
    background-size cover
    opacity .3

  &__shadow:nth-child(1)
    position absolute
    top 200rem

    width 50%

    opacity .3

  &__texture:nth-child(1)
    position absolute
    top 0
    transform translate(-5%, -45%)

    width 110%

  &__texture:nth-child(2)
    position absolute
    bottom 0
    transform translate(-5%, 55%)

    width 110%

  &__header
    $headerHorizontalMargin = 42rem

    position sticky
    top 38rem
    z-index $z-index-modal

    margin 0 $headerHorizontalMargin
    width calc(100% - 2 * $headerHorizontalMargin)

  &__section
  &__footer
    position relative

  &__button-up
    position fixed
    right 72rem
    bottom 72rem
    z-index $z-index-fixed

    &-enter-to,
    &-leave-from
      opacity 1

    &-enter-active,
    &-leave-active
      transition opacity $transition-basic-medium

    &-enter-from,
    &-leave-to
      opacity 0

  @media all and (max-width $mobile_media)

    &__shadow:nth-child(1)
      top 130rem

      width 100%

    &__texture:nth-child(1)
      transform translate(-20%, -42%)

      width 200%

    &__texture:nth-child(2)
      transform translate(-27%, -23%)

      width 280%

    &__header
      $headerHorizontalMargin = 8rem

      top 12rem

      margin 0 $headerHorizontalMargin
      width calc(100% - 2 * $headerHorizontalMargin)

    &__button-up
      right 16rem
      bottom 32rem
</style>
