import { defineStore } from 'pinia';
import { apiInstance } from '@/shared/api';

export const useLeadsStore = defineStore('leads', () => {
  async function bookCall(data) {
    return apiInstance.post('/telegram/leads/book-call', data)
      .then((axiosResponse) => ({
        result: axiosResponse.data.result,
        errors: {},
      }))
      .catch((axiosResponse) => ({
        result: false,
        errors: axiosResponse.response ? axiosResponse.response.data.errors : {},
      }));
  }

  async function sendQuestion(data) {
    return apiInstance.post('/telegram/leads/send-question', data)
      .then((axiosResponse) => ({
        result: axiosResponse.data.result,
        errors: {},
      }))
      .catch((axiosResponse) => ({
        result: false,
        errors: axiosResponse.response ? axiosResponse.response.data.errors : {},
      }));
  }

  return {
    bookCall,
    sendQuestion,
  };
});
