<template>
  <transition name="popup-template">
    <div v-if="state" class="popup-template">
      <div
        class="popup-template__background"
        aria-hidden="true"
        @click="close"
      ></div>
      <div class="popup-template__wrapper">
        <div class="popup-template__content" :class="$attrs.class">
          <basic-icon
            class="popup-template__close"
            icon="feather/x"
            @click="close"
          />
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue';
import { BasicIcon } from '@/shared/ui/Icon';

defineOptions({
  inheritAttrs: false,
});

const state = ref(false);

function open() {
  if (state.value) return;

  document.documentElement.classList.add('lock-scroll');
  state.value = true;
}

function close() {
  if (!state.value) return;

  document.documentElement.classList.remove('lock-scroll');
  state.value = false;
}

function toggle() {
  if (state.value) close();
  else open();
}

defineExpose({
  open,
  close,
  toggle,
});
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/devices'
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/z-index'

.popup-template
  position fixed
  top 0
  left 0
  z-index $z-index-modal

  width 100%
  height 100%
  overflow-x hidden
  overflow-y scroll

  &-enter-to
  &-leave-from
    opacity 1

  &-enter-to &__wrapper
  &-leave-from &__wrapper
    scale 1

  &-enter-active
    transition opacity .3s

  &-leave-active
    transition opacity .2s

  &-enter-active &__wrapper
  &-leave-active &__wrapper
    transition scale $transition-basic-short

  &-enter-from
  &-leave-to
    opacity 0

  &-enter-from &__wrapper
    scale 0

  &-leave-to &__wrapper
    scale .7

  &__background
    position fixed
    top 0
    left 0

    width 100%
    height 100%

    background rgba($gray-dark, .5)

  &__wrapper
    min-height 100%
    padding 30rem

    display grid
    justify-content center
    align-content center
    gap 8rem

    transition transform $transition-basic-medium

  &__content
    position relative
    z-index $z-index-top

    padding 32rem 48rem

    background rgb(0, 98, 0)
    background linear-gradient(135deg, rgba(0, 98, 0, 1) 0%, rgba(20, 56, 20, 1) 55%,
      rgba(0, 98, 0, 1) 100%)
    border 1px solid #B1B1B1
    border-radius 30rem

  &__close
    position absolute
    top 16rem
    right 16rem
    z-index $z-index-top

    padding 4rem
    width 24rem
    height 24rem
    box-sizing content-box

    justify-self right

    background-color #004E00
    color $white-primary
    border-radius 16rem

    transition background-color $transition-basic-short, color $transition-basic-short
    cursor pointer

    &:hover
      background-color #006200
      color $white-400

  @media all and (max-width $mobile_media)
    &__content
      padding 36rem 18rem
</style>
