import lodash from 'lodash';

function makeFormObjectByKeys(keys) {
  return keys.reduce((obj, key) => {
    const objectCopy = lodash.cloneDeep(obj);
    lodash.set(objectCopy, key, {
      value: '',
      valid: true,
      errors: [],
    });
    return objectCopy;
  }, {});
}

function addKeyToFormObject(formObject, key) {
  lodash.set(formObject, key, {
    value: '',
    valid: true,
    errors: [],
  });
}

function getValuesFromFormObjectByKeys(formObject, keys) {
  const formObjectCopy = lodash.cloneDeep(formObject);
  keys.forEach((key) => {
    const keyObject = lodash.get(formObject, key);
    lodash.set(formObjectCopy, key, keyObject ? keyObject.value : undefined);
  });
  return formObjectCopy;
}

function setValueToFormObjectKey(formObject, key, value) {
  lodash.set(formObject, `${key}.value`, value);
}

function setErrorsToFormObject(formObject, errors) {
  Object.entries(errors).forEach(([key, fieldErrors]) => {
    if (!lodash.get(formObject, key)) return;
    lodash.set(formObject, `${key}.errors`, fieldErrors);
    lodash.set(formObject, `${key}.valid`, false);
  });
}

function makeOptionsByKeys(array, keys, filter = []) {
  return array
    .filter((item) => !filter.includes(item[keys.value]))
    .map((item) => (
      Object.fromEntries(
        Object.entries(keys).map(
          ([neededKey, itemKey]) => [neededKey, item[itemKey]],
        ),
      )
    ));
}

export {
  makeFormObjectByKeys,
  addKeyToFormObject,
  getValuesFromFormObjectByKeys,
  setValueToFormObjectKey,
  setErrorsToFormObject,
  makeOptionsByKeys,
};
