function httpBuildQuery(baseURL, params) {
  const query = Object.entries(params)
    .filter(([, value]) => value !== null && value !== '' && !(Array.isArray(value) && !value.length))
    .map(([key, value]) => `${key}=${JSON.stringify(value)}`)
    .join('&');

  return `${baseURL}?${query}`;
}

export { httpBuildQuery };
