<template>
  <header class="home-header">
    <div class="home-header__logo">
      <basic-logo class="home-header__logo-icon" />
      <span class="home-header__logo-text">ThepartmentS</span>
    </div>
    <div class="home-header__text is-big-text on-desktop">
      {{ t('text') }}
    </div>
    <div class="home-header__buttons-wrapper">
      <basic-button class="home-header__button" :href="precrmUrl" secondary>
        {{ t('buttons.login') }}
      </basic-button>
      <basic-button
        class="home-header__button on-desktop"
        icon="other/cell-phone"
        big
        linear
        @click="emit('openBookCallPopup')"
      />
    </div>
  </header>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { BasicLogo } from '@/shared/ui/Logo';
import { BasicButton } from '@/shared/ui/Button';
import messages from '../model/homeHeaderMessages.json';

const precrmUrl = process.env.VUE_APP_PRECRM_URL;

const { t } = useI18n({ messages });

const emit = defineEmits(['openBookCallPopup']);
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/devices'

.home-header
  padding 8rem 8rem 8rem 30rem

  display grid
  grid-template-columns repeat(3, 1fr)
  justify-content space-between
  align-items center

  background linear-gradient(90deg, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, .3) 40%,
    rgba(0, 0, 0, .6) 65%, rgba(0, 0, 0, .42) 100%)
  border .5px solid $gray-primary
  border-radius 45rem
  backdrop-filter blur(5px)

  &__logo
    display flex
    align-items center
    gap 16rem

  &__logo-icon
    width 64rem
    height 64rem

  &__logo-text
    color $white-primary

    font-size 25rem
    line-height 27rem

  &__text
    margin auto
    width 300rem

    color $white-primary

    font-weight 200

    text-align center

  &__buttons-wrapper
    display flex
    justify-content flex-end
    gap 8rem

  @media all and (max-width $mobile_media)
    padding 8rem

    grid-template-columns repeat(2, 1fr)

    &__logo
      gap 6rem

    &__logo-icon
      width 30rem
      height 30rem

    &__logo-text
      font-size 12rem
      line-height 14rem
</style>
