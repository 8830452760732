<template>
  <section class="home-hero-section">
    <div class="home-hero-section__container">
      <div class="home-hero-section__column">
        <basic-logo class="home-hero-section__logo" with-text />
        <div class="home-hero-section__content">
          <p class="home-hero-section__text">
            {{ t('texts.0') }}
            <span>{{ t('texts.1') }}</span>
          </p>
          <div class="home-hero-section__buttons-wrapper">
            <basic-button
              class="home-hero-section__button"
              linear
              @click="emit('openBookCallPopup')"
            >
              {{ t('buttons.book-a-call') }}
            </basic-button>
            <basic-button
              class="home-hero-section__button on-desktop"
              icon="other/cell-phone"
              big
              linear
              @click="emit('openBookCallPopup')"
            />
          </div>
        </div>
      </div>
      <div class="home-hero-section__column">
        <picture class="home-hero-section__image">
          <source
            type="image/webp"
            media="(max-width: 480px)"
            :srcset="require('@/shared/assets/images/home-page/hero-section__photo--mobile.webp')"
          >
          <source
            type="image/png"
            media="(max-width: 480px)"
            :srcset="require('@/shared/assets/images/home-page/hero-section__photo--mobile.png')"
          >
          <source
            type="image/webp"
            :srcset="require('@/shared/assets/images/home-page/hero-section__photo--desktop.webp')"
          >
          <source
            type="image/png"
            :srcset="require('@/shared/assets/images/home-page/hero-section__photo--desktop.png')"
          >
          <img
            :src="require('@/shared/assets/images/home-page/hero-section__photo--mobile.png')"
            alt="photo"
            width="100%"
          >
        </picture>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { BasicLogo } from '@/shared/ui/Logo';
import { BasicButton } from '@/shared/ui/Button';
import messages from '../model/homeHeroSectionMessages.json';

const { t } = useI18n({ messages });

const emit = defineEmits(['openBookCallPopup']);
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/colors'
@require '@/shared/styles/utils/z-index'
@require '@/shared/styles/utils/devices'

.home-hero-section
  position relative

  padding 150rem 72rem 0
  max-height 730rem
  overflow hidden

  &__container
    display flex
    justify-content space-between

  &__column
    height max-content

    display grid
    gap 100rem

  &__logo
    width 461rem
    height 116rem

  &__text
    color $white-primary

    font-size 25rem
    font-weight 300
    line-height 27rem

    span
      color #2DE115

      font-weight 500

  &__content
    max-width 480rem

    display grid
    gap 40rem

  &__buttons-wrapper
    padding 10rem
    width max-content

    display flex
    gap 20rem

    background linear-gradient(90deg, rgba(16, 16, 16, 0) 0%, rgba(0, 0, 0, 1) 100%);
    border-radius 35rem

  &__button
    width max-content

  &__image
    margin-top -30rem
    width 590rem

  @media all and (max-width $mobile_media)
    padding 32rem 16rem 0

    &__container
      display grid
      justify-content center
      gap 10rem

    &__column
      justify-content center
      gap 0

    &__logo
      width 280rem

    &__content
      margin 0 auto
      width 240rem

      gap 0

    &__text
      font-size 12rem
      line-height 14rem

      text-align center

    &__buttons-wrapper
      position absolute
      bottom 80rem
      z-index $z-index-basic-1

      padding 0

      background none

    &__image
      margin-top 50rem
      width 580rem
</style>
