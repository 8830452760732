<template>
  <TransitionGroup name="toast-container" tag="div" class="toast-container">
    <basic-toast
      v-for="(toast, index) in toastsStore.getToastsList"
      :key="toast"
      class="toast-container__toast"
      :type="toast.type"
      @close="toastsStore.removeToast(index)"
    >
      <span v-html="toast.message" />
    </basic-toast>
  </TransitionGroup>
</template>

<script setup>
import { BasicToast } from '@/shared/ui/Toast';
import { useToastsStore } from '../model/toastsStore';

const toastsStore = useToastsStore();
</script>

<style scoped lang="stylus">
@require '@/shared/styles/utils/transition'
@require '@/shared/styles/utils/z-index'

.toast-container
  position relative
  transform translate(-50%)
  z-index $z-index-top

  width 470rem

  display flex
  flex-direction column-reverse
  gap 20rem

  &-move
  &-enter-active
  &-leave-active
    transition all $transition-basic-medium

  &-enter-to,
  &-leave-from
    opacity 1

  &-enter-from
    opacity 0

    transform translateY(-30rem) scale(.1)

  &-leave-to
    z-index -1

    opacity 0

    transform translateY(30rem) scale(.1)

  &-leave-active
    position absolute
</style>
